import Swal from 'sweetalert2';

async function handleError(err, history, customMsg) {
  if (err.response && err.response.status === 401) {
    await Swal.fire({
      title: 'Token inválido',
      text: 'O token expirou. Por favor, efetue login novamente',
      icon: 'info',
      timer: 3000,
      timerProgressBar: true,
    });

    localStorage.clear();
    history.push('/');
  } else {
    const axiosMsgError =
      err.isAxiosError &&
      err.response &&
      typeof err.response.data.message === 'string' &&
      err.response.data.message;

    await Swal.fire({
      icon: 'error',
      title: 'Algo deu errado',
      text:
        customMsg ||
        axiosMsgError ||
        'A solicitação resultou em erro. Por favor, tente novamente.',
    });
  }
}

export default handleError;
