import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import handleError from '../../../common/handleError';

function Formulary(
  {
    onSubmit,
    onInvalid,
    onError,
    beforeSubmit,
    className,
    children,
    isResettedAfterSubmit,
    isWrappedInFormTag = true,
    ...rest
  },
  ref
) {
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    ...rest,
  });

  const history = useHistory();

  const invoke = () =>
    methods
      .handleSubmit(onSubmit, onInvalid)()
      .catch((err) => {
        if (onError) {
          onError(err);
        } else {
          handleError(err, history);
        }
      });

  const submit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (!beforeSubmit) {
      invoke();
      return;
    }

    const shouldSubmit = await beforeSubmit();

    if (shouldSubmit) {
      invoke();
    }
  };

  const context = {
    ...methods,
    submit,
  };

  const { getValues, reset, formState } = methods;

  React.useImperativeHandle(ref, () => context);

  React.useEffect(() => {
    if (isResettedAfterSubmit && formState.isSubmitSuccessful) {
      reset();
    }
  }, [isResettedAfterSubmit, getValues, reset, formState]);

  return (
    <FormProvider {...context}>
      {isWrappedInFormTag ? (
        <form onSubmit={submit} className={`w-100 ${className ? className : ''}`} noValidate>
          {children}
        </form>
      ) : (
        children
      )}
    </FormProvider>
  );
}

export default React.forwardRef(Formulary);
