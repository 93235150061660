import React from 'react';
import { Controller } from 'react-hook-form';

import getFieldRule from '../../../utils/getFieldRule';
import TextInputBase from '../../TextInputBase';

function TextInput({
  onChangeText,
  onBlur,
  name,
  rules,
  shouldUnregister,
  defaultValue,
  errorMessage,
  ...rest
}) {
  return (
    <Controller
      name={name}
      rules={{
        ...getFieldRule({ isRequired: rest.isRequired }),
        ...rules,
      }}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue || ''}
      render={({ field, fieldState }) => (
        <TextInputBase
          value={field.value}
          error={fieldState.error?.message || errorMessage}
          onBlur={(e) => {
            field.onBlur();

            if (onBlur) {
              onBlur(e);
            }
          }}
          onChangeText={(input) => {
            field.onChange(input);

            if (onChangeText) {
              onChangeText(input);
            }
          }}
          {...rest}
        />
      )}
    />
  );
}

export default TextInput;
