import React from 'react';
import { mdiEye, mdiEyeOff } from '@mdi/js';

import Wrapper from './Wrapper';
import './styles.scss';

function TextInputBase({
  icons,
  label,
  error,
  type = 'input',
  options,
  tip,
  tipProps,
  isRequired,
  canToggleVisibility,
  onChangeText,
  onChange,
  ...rest
}) {
  const [isVisible, setIsVisible] = React.useState(false);

  const iconsIn = {
    ...(canToggleVisibility && {
      end: {
        mode: 'button',
        type: 'button',
        name: isVisible ? mdiEye : mdiEyeOff,
        onClick: () => setIsVisible((prevState) => !prevState),
      },
    }),
    ...icons,
  };

  const Component = type === 'textarea' ? 'textarea' : 'input';

  return (
    <Wrapper
      icons={iconsIn}
      label={label}
      error={error}
      tip={tip}
      tipProps={tipProps}
      isRequired={isRequired}
      isDisabled={rest.disabled}
    >
      <Component
        type={isVisible ? 'text' : type}
        onChange={(e) => {
          if (onChangeText) {
            onChangeText(e.target.value);
          }

          if (onChange) {
            onChange(e);
          }
        }}
        className={`
          text-input w-100 py-sm-2 border-0 typography-body-lg
          ${iconsIn.start ? 'pl-5' : 'pl-3'} 
          ${iconsIn.end ? 'pr-5' : 'pr-3'}
        `}
        {...rest}
      />
    </Wrapper>
  );
}

export default TextInputBase;