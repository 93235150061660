/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { mdiTooltipPlus } from '@mdi/js';

import Tooltip from '../../Tooltip';
import Icon from '../../Icon';

import './styles.scss';
function Wrapper({
  icons,
  label,
  error,
  tip,
  tipProps,
  children,
  isRequired,
  isDisabled,
}) {
  const iconStyle =
    'd-flex align-items-center justify-content-center position-absolute pe-none bottom-0 icon-style';

  const tooltip = tip && (
    <div
      className={`ml-2 ${label ? 'pt-4' : ''}`}
    >
      <Tooltip
        icon={<Icon name={mdiTooltipPlus} />}
        direction="bottom"
        tipWidth={196}
        {...tipProps}
      >
        {tip}
      </Tooltip>
    </div>
  );

  const caption = error && (
    <p className="mt-sm-1 mb-0 pl-2 typography-body-sm text-error">{error}</p>
  );

  return (
    <div className='w-100'>
      <div className='d-flex align-items-start'>
        <div
          className={`text-black position-relative ${isDisabled ? 'opacity-3' : ''} flex-fill`}
        >
          <label
            className={`w-100 m-0 text-label typography-label-md ${isDisabled ? 'text-black opacity-3' : ''}`}
          >
            {label && (
              <div className={`mb-sm-1 ${error ? 'text-error' : ''}`}>
                {label} {isRequired && '*'}
              </div>
            )}
            <div
              className={`text-black bg-white input-container ${error ? 'input-container-error' : 'input-container-focus'} ${isDisabled ? 'opacity-3' : ''}`}
            >
              {children}
            </div>
          </label>
          {icons?.start && (
            <div className={`${iconStyle} left-0`}>
              <Icon
                {...(typeof icons.start === 'string'
                  ? { name: icons.start }
                  : icons.start)}
                disabled={isDisabled}
              />
            </div>
          )}
          {icons?.end && (
            <div className={`${iconStyle} right-0`}>
              <Icon
                {...(typeof icons.end === 'string'
                  ? { name: icons.end }
                  : icons.end)}
                disabled={isDisabled}
              />
            </div>
          )}
        </div>

        {tooltip}
      </div>
      {caption}
    </div>
  );
}

export default Wrapper;
