import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import "./styles.scss";
import { Row, Col, Button } from "reactstrap";

import Page from "components/Page";
import { Formulary, MaskInput } from "components/ReactHookForm";

import Loading from "components/Loading";
import api from "config/api";
import errorRequest from "common/errorRequest";
import Swal from "sweetalert2";

export default function Home() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [min, setMin] = useState(Number(localStorage.getItem("qtdeMin")));
  const [max, setMax] = useState(Number(localStorage.getItem("qtdeMax")));

  function submitMinMax() {
    if (min < max) {
      Swal.fire({
        icon: "info",
        title: "Atenção",
        text: "Os itens mínimos e máximos do carrinho serão alterados. Tem certeza que deseja fazer isso?",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
      }).then(async (res) => {
        if (res.value) {
          setLoading(true);
          try {
            await api.put(`/user/min-max`, {
              min: min,
              max: max,
            });
            localStorage.setItem("qtdeMin", min);
            localStorage.setItem("qtdeMax", max);

            window.location.reload(false);
          } catch (error) {
            errorRequest(history, error);
          }
          setLoading(false);
        }
      });
    } else {
      Swal.fire({
        icon: "info",
        title: "Atenção",
        text: "O número mínimo de itens não pode ser igual ou maior que o máximo.",
      });
    }
  }

  return (
    <Page
      // Escolha entre title ou back
      title="Escolher o número mínimo e máximo de itens" // Titulo, usado em páginas principais
      // back // Usado para retornar a página anterior, normalmente a principal
      // topButtonLink // Botão em link com a cor primary
      // topButtonEva // Botão com a cor primary
    >
      <>
        {loading && <Loading />}
        <Formulary>
          <Row form className="container-inputs flex-wrap mx-auto">
            <Col className="flex-33">
              <div className="input-default">
                <MaskInput
                  name="minVariant"
                  label="Mínimo de itens"
                  placeholder="Mínimo"
                  mask="number"
                  isRequired
                  onChange={(e) => setMin(Number(e.target.value))}
                  value={min}
                  tip="Coloque 0 para não ter um mínimo de produtos no carrinho"
                />
              </div>
            </Col>
            <Col className="flex-33">
              <div className="input-default">
                <MaskInput
                  name="maxVariant"
                  label="Máximo de itens"
                  placeholder="Máximo"
                  mask="number"
                  isRequired
                  onChange={(e) => setMax(Number(e.target.value))}
                  value={max}
                  tip="Coloque 0 para não ter um máximo de produtos no carrinho"
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button
                className="button-eva"
                onClick={(e) => submitMinMax(e)}
                style={{ cursor: "pointer" }}
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Formulary>
      </>
    </Page>
  );
}
