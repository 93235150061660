import React from 'react';
import { Controller } from 'react-hook-form';

import getFieldRule from '../../../utils/getFieldRule';
import MaskInputBase from '../../MaskInputBase';

function MaskInput({
  onChangeValue,
  onBlur,
  name,
  rules,
  shouldUnregister,
  shouldRegister = true,
  defaultValue,
  ...rest
}) {
  if (!shouldRegister) {
    return (
      <MaskInputBase onBlur={onBlur} onChangeValue={onChangeValue} {...rest} />
    );
  }

  return (
    <Controller
      name={name}
      rules={{
        ...getFieldRule({ isRequired: rest.isRequired }),
        ...rules,
      }}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue != null ? defaultValue : ''}
      render={({ field, fieldState }) => (
        <MaskInputBase
          value={field.value}
          error={fieldState.error?.message}
          onBlur={(e) => {
            field.onBlur();

            if (onBlur) {
              onBlur(e);
            }
          }}
          onChangeValue={(value) => {
            field.onChange(value);

            if (onChangeValue) {
              onChangeValue(value);
            }
          }}
          {...rest}
        />
      )}
    />
  );
}

export default MaskInput;
