import React from 'react';
import 'styled-components/macro'

import Portal from '../Portal';

import './styles.scss';

function Tooltip({
  isMultiline = true,
  children,
  icon,
  size = 24,
  color = '#565758',
  direction = 'top',
  tipWidth,
  className = '',
}) {
  const [isFocused, setIsFocused] = React.useState(false);

  const [isHovered, setIsHovered] = React.useState(false);

  const wrapper = React.useRef(null);

  const y =
    wrapper.current &&
    wrapper.current.getBoundingClientRect().top + window.scrollY;

  const x =
    wrapper.current &&
    wrapper.current.getBoundingClientRect().left + window.scrollX;

  return (
    <button
      ref={wrapper}
      type="button"
      className={`app-tooltip btn-reset ${className}`}
      style={{ '--color': color }}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <div className="app-tooltip__icon">
        {typeof icon === 'string' ? (
          <i className={icon} style={{ fontSize: size }} />
        ) : (
          icon
        )}
      </div>

      {(isFocused || isHovered) && (
        <Portal>
          <div
            className={
              `app-tooltip__balloon _shadow-lg ${direction} typography-body-md ${isMultiline ? 'px-3 py-2' : 'px-2 py-1'}`
            }
            css={`
              white-space: ${tipWidth ? 'normal' : 'nowrap'};
              width: ${tipWidth ? `${tipWidth}px` : 'auto'};
              background: ${color};

              &::after {
                border: 8px solid ${color};
                border-right-color: transparent;
                border-left-color: transparent;
              }

              transform: translateX(-50%)
                ${direction === 'top' ? 'translateY(-100%)' : ''};

              position: absolute;
              left: ${x + wrapper.current.offsetWidth / 2}px;
            top: ${y +
              (direction === 'top' ? 0 : wrapper.current.offsetHeight)}px;
            `}
          >
            <div className="w-full">{children}</div>
          </div>
        </Portal>
      )}
    </button>
  );
}

export default Tooltip;

