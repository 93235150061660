import React from 'react';
import valueMask from '../../utils/valueMask';
import TextInput from '../TextInputBase';

function MaskInput({
  value,
  defaultValue,
  mask,
  options,
  shouldReturnMaskedValue,
  onChangeValue,
  onChangeText,
  ...rest
}) {
  const [innerValue, setInnerValue] = React.useState(() =>
    valueMask[mask](defaultValue || '', options)
  );

  const parsedValue = React.useRef(null);

  React.useEffect(() => {
    if (value == null || value === parsedValue.current) {
      return;
    }

    setInnerValue(valueMask[mask](value, options));
  }, [value, options, mask]);

  return (
    <TextInput
      value={innerValue}
      inputMode={valueMask[mask].input}
      onChangeText={(input) => {
        const masked = valueMask[mask](input, options);

        const parsed = valueMask[mask].parse(masked);

        parsedValue.current = parsed;

        setInnerValue(masked);

        if (onChangeValue) {
          onChangeValue(shouldReturnMaskedValue ? masked : parsed);
        }

        if (onChangeText) {
          onChangeText(input);
        }
      }}
      {...rest}
    />
  );
}

export default MaskInput;
