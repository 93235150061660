import IconBase from '@mdi/react';
import React from 'react';

import './styles.scss'
function Icon({
  mode = 'default',
  name,
  size: sizeIn = 'md',
  tip,
  color,
  className = '',
  ...rest
}) {
  const Tag = mode === 'button' ? 'button' : 'div';

  const size = { sm: 18, md: 24, lg: 36, xl: 48 }[sizeIn];

  const icon = <IconBase path={name} size={size / 24} />;

  return (
    <Tag
      className={`${mode === 'button' ? 'icon-container d-flex align-items-center justify-content-center rounded cursor-pointer text-black rounded p-2 icon-container border-0 pe-auto mx-auto' : ''} ${className}`}
      {...rest}
    >
      <div
        className="d-flex align-items-center justify-content-center text-gray-custom"
        style={{
          width: size,
          height: size,
          color
        }}
      >
        {icon}
      </div>

      {mode === 'button' && tip && !rest.disabled && (
        <p className="typography-label-lg icon-tip shadow-md rounded text-white bg-gray-500 px-sm-2 py-sm-1">
          {tip}
        </p>
      )}
    </Tag>
  );
}

export default Icon;
